import React from 'react'
import styles from './Main.module.css'
import Link from './Link/Link';
import googlePlay from '../../assets/img/app_google.png'
import { useTranslation } from 'react-i18next';

const Main = () => {
    const [t, i18n] = useTranslation();
    return (
        <main className={styles.main}>
            <div className={styles.titles}>
                <h1>{t('Live wallpapers')}</h1>
                <h3>{t('The Best wallpapers')}</h3>
            </div>
            <div className={styles.links}>
                <Link img={googlePlay} type="google" link={'https://0kgrk.app.link/main'} />
            </div>
        </main>
    )
}
export default Main;