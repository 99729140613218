import firebase from 'firebase';
import 'firebase/analytics';

export const firebaseConfig = {
  apiKey: 'AIzaSyASfdv3VethyMBzJzSc2HT6FEp-Q52kDs8',
  authDomain: 'wallpapers-e0138.firebaseapp.com',
  projectId: 'wallpapers-e0138',
  storageBucket: 'wallpapers-e0138.appspot.com',
  messagingSenderId: '878719581525',
  appId: '1:878719581525:web:5556bc843c06205cf1c3dc',
  measurementId: 'G-SH8G1DSHGK',
};

firebase.initializeApp(firebaseConfig);

export const firebaseAnalytics = firebase.analytics();